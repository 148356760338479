import { Search, Icon, Swipe, SwipeItem, Tag, List } from 'vant'
import Login from '../../intercept/Login.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import Sift from '../common/Sift.vue'
import Clue from '../../common/Clue.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        Sift: Sift,
        Clue: Clue,
        [Search.name]: Search,
        [Icon.name]: Icon,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Tag.name]: Tag,
        [List.name]: List
    },
    data () {
        return {
            loadingShow: false,
            finished: false,
            keyword: '',
            classifyCode: '',
            page: { current: 0, size: 20, last: 0 },
            certList: []
        }
    },
    mounted () {
        var query = this.$route.query
        this.classifyCode = query.classifyCode
        this.retrieveCertPage()
        this.$refs.share.default('main')
    },
    methods: {
        search () {
            this.page.current = 0
            this.finished = false
            this.certList = []
            this.retrieveCertPage()
            this.createSearchClue()
        },
        async retrieveCertPage () {
            if (!this.finished) {
                this.loadingShow = true
                this.page.current = this.page.current + 1
                var pd = { keyword: this.keyword, classifyCode: this.classifyCode, current: this.page.current, size: this.page.size }
                var { data: res } = await this.$http.post(this.BMS_URL + '/cert/certAPC/retrieveCertPage', this.$qs.stringify(pd))
                if (res.status === '200') {
                    console.log(res.data)
                    this.certList = this.certList.concat(res.data)
                    this.page.last = res.page.last
                    if (res.data.length <= 0) {
                        this.finished = true
                    }
                }
                this.loadingShow = false
            }
        },
        classifySearch (param) {
            this.finished = false
            this.page.current = 0
            this.classifyCode = param
            this.certList = []
            this.retrieveCertPage()
        },
        detail (certCode, issuerCode) {
            var key = window.const.global.CERT_DETAIL_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/cert/detail', query: { certCode: certCode, issuerCode: issuerCode } })
        },
        createSearchClue () {
            var date = new Date()
            var dt = date.getTime()
            var param = { keyword: this.keyword, classifyCode: this.classifyCode, url: window.location.href }
            var content = '证书考培搜索'
            this.$refs.clue.createClue('CERT_SEARCH_CERT', dt, JSON.stringify(param), content)
        },
        back () {
            var back = window.sessionStorage.getItem(window.const.global.CERT_SEARCH_INDEX_BACK)
            if (back !== undefined && back !== null && back !== '') {
                window.location.href = back
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '已返回到最上层'
                })
            }
        }
    }
}
