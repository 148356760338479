import { DropdownMenu, DropdownItem, TreeSelect } from 'vant'
export default {
    components: {
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [TreeSelect.name]: TreeSelect
    },
    data () {
        return {
            activeId: '',
            activeIndex: 1,
            condition: {},
            classifyChose: {},
            classify: '',
            classifys: [{ text: '全部分类', value: '' }]
        }
    },
    props: {
        callback: Function
    },
    mounted () {
        this.retrieveClassifySelect()
    },
    methods: {
        confirmClassify () {
            this.condition.classifyCode = this.classify.id
            this.callback(this.condition)
        },
        async retrieveClassifySelect () {
            var pd = { upperCode: '0' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/cert/classify/retrieveClassifySelect', this.$qs.stringify(pd))
            if (res.status === '200') {
                res.data.forEach(element => {
                    this.classifys.push({ text: element.name, value: element.code })
                })
            }
        },
        classifyChange (item) {
            this.callback(this.classify)
        }
    }
}
