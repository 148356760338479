<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            placeholder="请输入搜索关键词"
            ref="keyword"
            show-action
            ><template #action>
              <div @click="search">搜索</div>
            </template></van-search
          >
        </van-col>
      </van-row>
    </div>
    <div class="list">
      <van-row class="title">
        <van-col span="12" class="text"
          ><van-icon name="notes-o" /> 证书列表</van-col
        >
      </van-row>
      <Sift :callback="classifySearch" />
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        offset="0"
        :immediate-check="false"
        @load="retrieveCertPage"
      >
        <template #default>
          <div
            class="major"
            v-for="cert in certList"
            :key="cert.code"
            @click="detail(cert.code, cert.issuerCode)"
          >
            <div class="name text-short">
              {{ cert.name }}
            </div>
            <div class="grade">
              颁发机构：<van-tag :color="COLOR_M">{{
                cert.issuerName
              }}</van-tag>
            </div>
          </div>
        </template>
      </van-list>
    </div>
    <Clue ref="clue" />
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit
      ref="logVisit"
      module-type="CRT"
      module-label="CRT_SEARCH_INDEX"
    />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
