<template>
  <div>
    <van-dropdown-menu class="dropdown">
      <van-dropdown-item
        v-model="classify"
        :options="classifys"
        @click="classifyChange"
      ></van-dropdown-item>
    </van-dropdown-menu>
  </div>
</template>
<script>
import Sift from './Sift.js'
export default {
  ...Sift
}
</script>
<style lang="less" scoped>
@import './Sift.less';
</style>
